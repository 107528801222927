import "./statistics.css";
import { useState, useEffect } from "react";
import axiosConfig from "../../BaseUrl";
import { Link, useParams } from "react-router-dom";

function StatisticsPlace() {
  const { ttjId, blockId, floorId, roomId } = useParams();
  const [placeData, setPlaceData] = useState([]);

  useEffect(() => {
    const getFloorData = async () => {
      try {
        const { data } = await axiosConfig.get(
          `/ttj-rahbari/place-students/${roomId}`
        );
        setPlaceData(data.places);
      } catch (error) {
        console.error(error);
      }
    };
    getFloorData();
  }, []);

  return (
    <div className="ttj">
      <div className="d-flex justify-content-between">
        <h5 className="ttj-title">
          <Link to={`/TTJ admin dashboard/ttj`}>{placeData[0]?.accommodation?.name}</Link> / 
          <Link to={`/TTJ admin dashboard/ttj/block/${ttjId}`}> {placeData[0]?.block?.name} </Link> /
          <Link to={`/TTJ admin dashboard/ttj/block/${ttjId}/floor/${blockId}`}> {placeData[0]?.floor?.name} </Link> /
          <Link to={`/TTJ admin dashboard/ttj/block/${ttjId}/floor/${blockId}/room/${floorId}`}> {placeData[0]?.room?.name} </Link> / Joy
        </h5>
        <h5 className="ttj-title2">TTJ JOY STATISTIKASI</h5>
      </div>
      <hr />
      <table className="table my-4">
        <thead>
          <tr>
            <th>Joy nomi</th>
            <th>Talaba hemis id</th>
            <th>Talaba to'liq ismi</th>
            <th>Talaba fakulteti</th>
          </tr>
        </thead>
        <tbody>
          {placeData.map((item) => {
            if (item.students.length === 1) {
              return (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.students[0].hemis_id}</td>
                  <td>{item.students[0].fullname}</td>
                  <td>{item.students[0].department}</td>
            </tr>
              )
            }
          })}
        </tbody>
      </table>
    </div>
  )
}

export default StatisticsPlace