import React, { useState, useEffect } from "react";
import { Button, Checkbox, Form, Input, message } from 'antd';
import axios from 'axios';
import logo from "../../../assets/logoadu.png"
import { useNavigate } from "react-router-dom";
import "./login.css"
import axiosConfig from "../../../BaseUrl";

const Login = () => {


    const [text, setText] = useState()
    const navigate = useNavigate()

    const onFinish = (values) => {
        // console.log( "shu values",values);
        axiosConfig.post(`/login`, values).then(res=>{
            console.log(res.data.user);
            sessionStorage.setItem("ttName",res.data.user.accommodation.name)
            sessionStorage.setItem("ttId",res.data.user.accommodation.id)
            sessionStorage.setItem( "token", res.data.token)
            sessionStorage.setItem( "role", res.data.user.role)
            sessionStorage.setItem( "name", res.data.user.name)
            sessionStorage.setItem( "email", res.data.user.email)
            if (res.data.user.role === 0 ) {
                navigate('/super admin dashboard')
            }
            else if(res.data.user.role === 1) {
                 navigate('/admin dashboard')
            }
            else if(res.data.user.role === 2) { navigate('/TTJ admin dashboard')
            }
            else if(res.data.user.role === 3) { navigate('/super admin dashboard')
            }
           }).catch(err=>{
            console.log(err.response.data);
            message.error(err.response.data.message)
           })
    };

    const onFinishFailed = (errorInfo) => {
        //   console.log("Salom");
        // setText("Maydonlarni to'liq to'ldiring")
        // git add . test
    };

    return (
        <div className="container">
            <div className="row for-login">
                <div className="col-6 shadow" style={{padding:"2% 5%"}}>
                    <div>
                            <Form name="basic" initialValues={{ remember: true, }} onFinish={onFinish}
                                onFinishFailed={onFinishFailed} autoComplete="off">
                                <div className="mb-5" style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <div style={{ width: "150px", height: "150px", display: "flex", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} >
                                        <p style={{ fontSize: "35px" }}><img style={{ width: "120px" }} src={logo} /></p>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="">Login</label>
                                    <Form.Item name="email"
                                        rules={[
                                            {
                                                required: true, message: 'Please input your username!',
                                            },
                                            {
                                                validator: (_, value = '') => !value.includes(" ") ? Promise.resolve() : Promise.reject(new Error("No spaces allowed"))
                                            }
                                        ]} >
                                        <Input style={{height:"42px"}}/>
                                    </Form.Item>
                                    <label htmlFor="">Parol</label>
                                    <Form.Item className="passwordInput" name="password"
                                        rules={[
                                            {
                                                required: true, message: 'Please input your password!',
                                            },
                                            {
                                                validator: (_, value = '') => !value.includes(" ") ? Promise.resolve() : Promise.reject(new Error("No spaces allowed"))
                                            }
                                        ]} >
                                        <Input.Password style={{height:"42px"}}/>
                                    </Form.Item>
                                    <Form.Item
                                    >
                                        <Button style={{ width: "100%", height:"42px" }} type="primary" htmlType="submit">
                                            Login
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login