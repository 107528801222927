import React, { useState } from "react";
import logo from "../assets/logoadu.png";
import {
  DownSquareOutlined,
  BankOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { FcStatistics } from "react-icons/fc";
import PrivateUsernRoute from "../Privates/PrivateUserRoute";
import AddTalabaPlace from "../admin/pages/AddTalabaPlace/AddTalabaPlace";
import UserListTTJBoshliq from "./userListTTJBoshliq/userListTTJBoshliq";
import PrivateTTJBoshliqRoute from "../Privates/PrivateTTJBoshliqRoute";
import TTJboshliqArxiv from "./Arxiv/TTJboshliqArxiv";
import StatisticsTTJ from "./Statistic/StatisticsTTJ";
import StatisticsBlock from "./Statistic/StatisticsBlock";
import AvailablePlaces from "./Statistic/AvailablePlaces";
import StatisticsFloor from "./Statistic/StatisticsFloor";
import StatisticsRoom from "./Statistic/StatisticsRoom";
import StatisticsPlace from "./Statistic/StatisticsPlace";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const TTJadminMain = () => {
  const navigate = useNavigate();
  const ttName = sessionStorage.getItem("ttName");
  const items = [
    // getItem(<div  onClick={()=>navigate('/dashboard/create admin')}>biriktirish</div>, '2', <DesktopOutlined onClick={()=>navigate('/super admin dashboard/create admin')} />),
    // getItem('User', 'sub1', <UserOutlined />, [
    //     getItem('Tom', '3'),
    //     getItem('Bill', '4'),
    //     getItem('Alex', '5'),
    // ]),
    getItem(
      <div onClick={() => navigate("/TTJ admin dashboard")}>
        Talabalar ro'yxati
      </div>,
      "3",
      <UsergroupAddOutlined
        onClick={() => navigate("/TTJ admin dashboard")}
      />
    ),
    getItem(
      <div onClick={() => navigate("/TTJ admin dashboard/arxiv")}>
        Arxiv
      </div>,
      "1",
      <DownSquareOutlined 
        onClick={() => navigate("/TTJ admin dashboard/arxiv")}
      />
    ),
    getItem(
        <div onClick={() => navigate("/TTJ admin dashboard/statistic")}>
          TTJ statistika
        </div>,
        "4",
        <FcStatistics onClick={() => navigate("/TTJ admin dashboard/statistic")} />
      ),
  ];
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const logOut = () => {
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <div>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div className="demo-logo mt-3"></div>
          <div className="demo-logo-vertical demo-logo">
            <img className="img-fluid" src={logo} alt="" />
          </div>
          <Menu
            style={{ padding: "30px 0 0 0" }}
            theme="dark"
            defaultSelectedKeys={["1"]}
            mode="inline"
            items={items}
          />
        </Sider>
        <Layout>
          {/* <Header style={{ padding: 10, background: colorBgContainer, }} /> */}
          <Content style={{ margin: "0 16px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>
                  {sessionStorage.getItem("name")}
                </Breadcrumb.Item>
              </Breadcrumb>
              <div>
                <button
                  style={{
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={logOut}
                  className="btn btn-danger"
                >
                  <LogoutOutlined /> Chiqish
                </button>
              </div>
            </div>

            <div
              style={{
                padding: 24,
                minHeight: 360,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Routes>
                <Route element={<PrivateTTJBoshliqRoute />}>
                  <Route path="/" element={<UserListTTJBoshliq />} />
                  <Route path="/arxiv" element={<TTJboshliqArxiv />} />
                  <Route path="/statistic" element={<StatisticsTTJ />} />

                 {/* Statistikalar ttj rahbar uchun */}

                  <Route path="/ttj/block/:ttjId" element={<StatisticsBlock />} />
                  <Route path="/ttj/block/:ttjId/floor/:blockId" element={<StatisticsFloor />}/>
                  <Route path="/ttj/block/:ttjId/floor/:blockId/room/:floorId" element={<StatisticsRoom />} />
                  <Route path="/ttj/block/:ttjId/floor/:blockId/room/:floorId/place/:roomId" element={<StatisticsPlace />}/>
                  <Route path="/availablePlaces" element={<AvailablePlaces />} />
                  
                </Route>
              </Routes>
            </div>
          </Content>

          <Footer
            style={{
              textAlign: "center",
            }}
          >
            TTJ ADU {new Date().getFullYear()} Created by <b>RTTRM</b>
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default TTJadminMain;
