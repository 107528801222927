import "./statistics.css";
import { useState, useEffect } from "react";
import axiosConfig from "../../BaseUrl";
import { Link, useParams } from "react-router-dom";

function StatisticsFloor() {
  const { ttjId, blockId } = useParams();
  const [floorData, setFloorData] = useState([]);

  useEffect(() => {
    const getFloorData = async () => {
      try {
        const { data } = await axiosConfig.get(
          `/super-admin/floor-places/${blockId}`
        );
        setFloorData(data.floors);
      } catch (error) {
        console.error(error);
      }
    };
    getFloorData();
  }, []);

  return (
    <div className="ttj">
      <div className="d-flex justify-content-between">
        <h5 className="ttj-title">
          <Link to="/super admin dashboard/ttj">{floorData[0]?.accommodation?.name}</Link> /
          <Link to={`/super admin dashboard/ttj/block/${ttjId}`}> {floorData[0]?.block?.name} </Link> / Etaj
        </h5>
        <h5 className="ttj-title2">TTJ ETAJ STATISTIKASI</h5>
      </div>
      <hr />
      <table className="table my-4">
        <thead>
          <tr>
            <th>№</th>
            <th>Qavat nomi</th>
            <th>Qavat joylar soni</th>
            <th>Qavat band joylar soni</th>
            <th>Qavat bo'sh joylar soni</th>
          </tr>
        </thead>
        <tbody>
          {floorData.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>
                <Link
                to={`/super admin dashboard/ttj/block/${ttjId}/floor/${blockId}/room/${item.id}`}
                >
                  {item.name}
                </Link>
              </td>
              <td>{item.allPlaces}</td>
              <td>{item.busyPlaces}</td>
              <td>{item.allPlaces - item.busyPlaces}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default StatisticsFloor;
