import React, { useState } from "react";
import logo from "../assets/logoadu.png";
import "./main.css";
import {
  DesktopOutlined,
  BankOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  ProfileOutlined,
  LogoutOutlined,
  DownSquareOutlined
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import MainTTJ from "./pages/Create-TTJ/Create-ttj";
import CreateAdmin from "./pages/CreateAdmin/Create-admin";
import PrivateAdminRoute from "../Privates/PrivateAdminRoute";
import CreateBlock from "./pages/CreateBlock/CreateBlock";
import CreateEtaj from "./pages/CreateEtaj/CreateEtaj";
import CreateXona from "./pages/CreateXona/CreateXona";

import { FcStatistics } from "react-icons/fc";
import StatisticsTTJ from "./statistics/StatisticsTTJ";
import StatisticsBlock from "./statistics/StatisticsBlock";
import StatisticsFloor from "./statistics/StatisticsFloor";
import StatisticsRoom from "./statistics/StatisticsRoom";
import StatisticsPlace from "./statistics/StatisticsPlace";
import AvailablePlaces from "./statistics/AvailablePlaces";
import AddTalabaSuperAdmin from "./pages/TalabaBiriktirish/AddTalabaSuperAdmin";
import StudentsList from "./pages/StudentList/StudentList";
import MyProfile from "./pages/MyProfile/MyProfile";
import SuperAdminMyProfile from "./pages/MyProfile/MyProfile";
import Arxiv from "./pages/Arxiv/Arxiv";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const Main = () => {
  const navigate = useNavigate();

  const role = sessionStorage.getItem('role')
  
  const items = [
    getItem(
      <div onClick={() => navigate("/super admin dashboard")}>
        {
          role == 3 ? "TTJlar ro'yxati" : 'TTJ yaratish'
        }
      </div>,
      "1",
      <BankOutlined onClick={() => navigate("/super admin dashboard")} />
    ),
    getItem(
      <div onClick={() => navigate("/super admin dashboard/create admin")}>
        Komendantlar
      </div>,
      "2",
      <DesktopOutlined
        onClick={() => navigate("/super admin dashboard/create admin")}
      />
    ),
    role == 3 ? "" :
    getItem(
      <div onClick={() => navigate("/super admin dashboard/add student")}>
        Talaba biriktirish
      </div>,
      "4",
      <UserOutlined
        onClick={() => navigate("/super admin dashboard/add student")}
      />
    )
    ,
    getItem(
      <div onClick={() => navigate("/super admin dashboard/students list")}>
        Talabalar ro'yxati
      </div>,
      "5",
      <UsergroupAddOutlined
        onClick={() => navigate("/super admin dashboard/students list")}
      />
    ),
    getItem(
      <div onClick={() => navigate("/super admin dashboard/my profile")}>
        Mening profilim
      </div>,
      "6",
      <ProfileOutlined
        onClick={() => navigate("/super admin dashboard/my profile")}
      />
    ),
    getItem(
      <div onClick={() => navigate("/super admin dashboard/arxiv")}>
        Arxiv
      </div>,
      "7",
      <DownSquareOutlined
        onClick={() => navigate("/super admin dashboard/arxiv")}
      />
    ),
    getItem(
      <div onClick={() => navigate("/super admin dashboard/ttj")}>
        TTJ statistika
      </div>,
      "3",
      <FcStatistics onClick={() => navigate("/super admin dashboard/ttj")} />
    ),
  ];
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const logOut = () =>{
    sessionStorage.clear()
    navigate('/')
}

  return (
    <div>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider className="test-sider"
          // style={{maxWidth:"1000px", width:"1000px", border: "1px solid red"}}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div className="demo-logo mt-3"></div>
          <div className="demo-logo-vertical demo-logo">
            <img className="img-fluid" src={logo} alt="" />
          </div>
          <Menu
            style={{ padding: "30px 0 0 0" }}
            theme="dark"
            defaultSelectedKeys={["1"]}
            mode="inline"
            items={items}
          />
        </Sider>
        <Layout>
          {/* <Header style={{ padding: 10, background: colorBgContainer, }} /> */}
          <Content style={{ margin: "0 16px" }}>
          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
              <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Super admin</Breadcrumb.Item>
                <Breadcrumb.Item>
                  {sessionStorage.getItem("name")}
                </Breadcrumb.Item>
              </Breadcrumb>
              <div>
              <button style={{height:"30px", display:"flex", alignItems:"center"}} onClick={logOut} className="btn btn-danger"><LogoutOutlined /> Chiqish</button>
              </div>
            </div>
            <div
              style={{
                padding: 24,
                minHeight: 360,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Routes>
                <Route element={<PrivateAdminRoute />}>
                  <Route path="/" element={<MainTTJ />} />
                  <Route path="/create block" element={<CreateBlock />} />
                  <Route path="/create etaj" element={<CreateEtaj />} />
                  <Route path="/create xona" element={<CreateXona />} />
                  {/* <Route path='/create joy' element={<CreateJoy />} /> */}
                  <Route path="/create admin" element={<CreateAdmin />} />
                  <Route path="/add student" element={<AddTalabaSuperAdmin />} />
                  <Route path="/students list" element={<StudentsList />} />
                  <Route path="/my profile" element={<SuperAdminMyProfile />} />
                  <Route path="/arxiv" element={<Arxiv />} />

                  {/* STATISTICS ROUTES BELOW: */}
                  <Route path="/ttj" element={<StatisticsTTJ />} />
                  <Route path="/ttj/block/:ttjId" element={<StatisticsBlock />}/>
                  <Route path="/ttj/block/:ttjId/floor/:blockId" element={<StatisticsFloor />}/>
                  <Route path="/ttj/block/:ttjId/floor/:blockId/room/:floorId" element={<StatisticsRoom />} />
                  <Route path="/ttj/block/:ttjId/floor/:blockId/room/:floorId/place/:roomId" element={<StatisticsPlace />}/>
                  <Route path="/availablePlaces" element={<AvailablePlaces />} />
                </Route>
              </Routes>
            </div>
          </Content>

          <Footer
            style={{
              textAlign: "center",
            }}
          >
            TTJ ADU {new Date().getFullYear()} Created by <b>RTTRM</b>
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default Main;
