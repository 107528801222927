import React, { useEffect, useState } from "react";
import axiosConfig from "../../BaseUrl";
import { Link } from "react-router-dom";

function AvailablePlaces() {
  const [availablePlaces, setAvailablePlaces] = useState([]);

  useEffect(() => {
    const getAvailablePlaces = async () => {
      try {
        const { data } = await axiosConfig.get("/ttj-rahbari/places-all");
        setAvailablePlaces(data.places);
      } catch (error) {
        console.error(error);
      }
    };
    getAvailablePlaces();
  }, []);

  return (
    <div className="ttj">
      <div className="d-flex justify-content-between">
        <Link to="/TTJ admin dashboard/ttj">
          <h5 className="ttj-title">TTJ STATISTIKASI</h5>
        </Link>
      </div>
      <hr />
      <table className="table my-4">
        <thead>
          <tr>
            <th>№</th>
            <th>TTJ nomi</th>
            <th>Blok nomi</th>
            <th>Qavat nomi</th>
            <th>Xona raqami</th>
            <th>Joy raqami</th>
          </tr>
        </thead>
        <tbody>
          {availablePlaces.map((item) => {
            if (item.Holati === "Bo'sh") {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.TTJ}</td>
                  <td>{item.Block}</td>
                  <td>{item.Qavat}</td>
                  <td>{item.Xona}</td>
                  <td>{item["Joy raqami"]}</td>
                </tr>
              );
            }
          })}
          {/* {availablePlaces.map((item) => (
        <tr key={item.id}>
          {console.log(item)}
        </tr>
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>
            <Link
            to={`/super admin dashboard/ttj/block/${ttjId}/floor/${blockId}/room/${item.id}`}
            >
              {item.name}
            </Link>
          </td>
          <td>{item.allPlaces}</td>
          <td>{item.busyPlaces}</td>
          <td>{item.allPlaces - item.busyPlaces}</td>
        </tr>
      ))} */}
        </tbody>
      </table>
    </div>
  );
}

export default AvailablePlaces;
