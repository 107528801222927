import axios from "axios";
// let token = ""


let axiosConfig  = axios.create();

const onRequest = (config) => {
    config.headers.Authorization = `Bearer ${sessionStorage.getItem('token')}`;
    config.baseURL = "https://ttjapi.adu.uz/api";

    return config;
}

axiosConfig.interceptors.request.use(onRequest)


export default axiosConfig
