import { useState, useEffect } from "react";
import axiosConfig from "../../BaseUrl";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import FileSaver from "file-saver"

function Block() {
  const ttjId = sessionStorage.getItem("ttId");
  const [accommodation, setAccommodation] = useState([]);
  const [blockData, setBlockData] = useState([]);

  
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const exportToCSV = () => {
      axiosConfig.get(`https://ttjapi.adu.uz/api/super-admin/places-all?accommodation_id=${ttjId}`).then(res=>{
        // console.log(res.data.places);
        let data = res.data.places
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const datas = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(datas, `${new Date().getFullYear()} - TTJ statistikasi` + fileExtension);
      }).catch(err=>{
        console.log(err);
      })
        
      };

  useEffect(() => {
    const getAccommodationData = async () => {
      try {
        const { data } = await axiosConfig.get(
          `/super-admin/block-statistic/${ttjId}`
        );
        setAccommodation(data);
      } catch (error) {
        console.error(error);
      }
    };
    getAccommodationData();
  }, []);

  useEffect(() => {
    const getBlockData = async () => {
      try {
        const { data } = await axiosConfig.get(
          `/super-admin/block-places/${ttjId}`
        );
        setBlockData(data.blocks);
      } catch (error) {
        console.error(error);
      }
    };
    getBlockData();
  }, []);

  console.log("accommodation ", accommodation);

  return (
    <div className="ttj">
      <div className="d-flex justify-content-between">
        <h5 className="ttj-title">
          <Link to="/super admin dashboard/ttj"></Link>TTJ BLOK STATISTIKASI
        </h5>
        <button className="btn btn-primary" onClick={exportToCSV}>excel</button>
        {/* <h5 className="ttj-title2">TTJ BLOK STATISTIKASI</h5> */}
      </div>
      <hr />
      <div className="row ">
        <div className="col-xxl-3 col-md-6">
          <div className="card info-card sales-card bg-primary border-0">
            <div className="card-body">
              <h5 className="card-title card-info text-white">
                {accommodation.length !== 0 &&
                  "Umumiy blok soni: " + accommodation.countBlocks + " ta"}
              </h5>
            </div>
          </div>
        </div>
        <div className="col-xxl-3 col-md-6">
          <div className="card info-card sales-card bg-warning border-0">
            <div className="card-body">
              <h5 className="card-title text-white">
                {accommodation.length !== 0 &&
                  "Umumiy joylar soni: " + accommodation.countAllPlaces + " ta"}
              </h5>
            </div>
          </div>
        </div>
        <div className="col-xxl-3 col-md-6">
          <div className="card info-card sales-card bg-danger border-0">
            <div className="card-body">
              <h5 className="card-title text-white">
                {accommodation.length !== 0 &&
                  "Band joylarni soni: " +
                    accommodation.countBusyPlaces +
                    " ta"}
              </h5>
            </div>
          </div>
        </div>
        <div className="col-xxl-3 col-md-6">
          <div className="card info-card sales-card bg-success border-0">
            <div className="card-body">
              <Link to="/admin dashboard/availablePlaces">
                <h5 className="card-title text-white">
                  {accommodation.length !== 0 &&
                    "Bo'sh joylarni soni: " +
                      Number(
                        accommodation.countAllPlaces -
                          accommodation.countBusyPlaces
                      ) +
                      " ta"}
                </h5>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <table className="table my-4">
        <thead>
          <tr>
            <th>№</th>
            <th>Blok nomi</th>
            <th>Blok joylar soni</th>
            <th>Blok band joylar soni</th>
            <th>Blok bo'sh joylar soni</th>
          </tr>
        </thead>
        <tbody>
          {blockData.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>
                <Link to={`/admin dashboard/block/${item.id}`}>
                  {/* <Route path="/block" element={<Block />} /> */}
                  {item.name}
                </Link>
              </td>
              <td>{item.allPlaces}</td>
              <td>{item.busyPlaces}</td>
              <td>{item.allPlaces - item.busyPlaces}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Block;
