import React, { useState, useMemo, useEffect } from "react";
import { PlusCircleOutlined, EditOutlined, HomeOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Form, Breadcrumb, message, Tooltip, ConfigProvider, Segmented } from 'antd';
import Highlighter from 'react-highlight-words';
import axiosConfig from "../../../BaseUrl";
import { useNavigate, Link } from "react-router-dom";

const buttonWidth = 80;

const CreateXona = () => {
    // const text = ;
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [name, setName] = useState("")
    const [nameJoy, setNameJoy] = useState("")
    const navigate = useNavigate()
    const [active, setActive] = useState('')
    const [id, setId] = useState('')
    const [joyId, setJoyId] = useState('')
    const [form] = Form.useForm();

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setOpen(false);
    };
    const handleCancel = () => {
        setName("")
        setOpen(false);
        setId("")
    };

    const showModal2 = () => {
        setOpen2(true);
    };
    const handleOk2 = () => {
        setOpen2(false);
    };
    const handleCancel2 = () => {
        setName("")
        setOpen2(false);
        setId("")
    };

    const showModal3 = () => {
        setOpen3(true);
    };
    const handleOk3 = () => {
        setOpen3(false);
    };
    const handleCancel3 = () => {
        setName("")
        setOpen3(false);
        setId("")
    };

    const showModal4 = () => {
        setOpen4(true);
    };
    const handleOk4 = () => {
        setOpen4(false);
    };
    const handleCancel4 = () => {
        setName("")
        setOpen4(false);
    };

    const accommodation_id = sessionStorage.getItem("ttId");
    const block_id = sessionStorage.getItem("BlockId");
    const floor_id = sessionStorage.getItem("floorId");
    const room_id = sessionStorage.getItem("roomId");


    const onFinish = () => {
        handleCancel()
        axiosConfig.post(`/admin/room`, { name, accommodation_id, block_id, floor_id }).then(res => {
            message.success(res.data.message)
            setName("")
            form.resetFields();
            getXona()
        }).catch(err => {
            console.log(err.response.data);
            message.error(err.response.data.errors)
        })
    };
    const onFinish2 = () => {
        handleCancel3()
        axiosConfig.post(`/admin/place`, { name, accommodation_id, block_id, floor_id, room_id }).then(res => {
            message.success(res.data.message)
            setName("")
            form.resetFields();
            getXona()
        }).catch(err => {
            console.log(err.response.data);
            message.error(err.response.data.errors)
        })
    };
   


    const [data, setData] = useState();

    const getXona = () => {
        axiosConfig.get(`/admin/room/${floor_id}`).then(res => {
            // console.log(res.data.rooms);
            setData(res.data.rooms)
        }).catch(err => {
            console.log(err);
        })
    }

    const getEdit = (id) => {
        axiosConfig.get(`/admin/room/show/${id}`).then(res => {
            // message.success(res.data.message)

            setName(res.data.room.name)
            showModal2()
            // getTTJ()
        }).catch(err => {
            console.log(err);
        })
    }

    const getJoyEdit = (id) => {
        axiosConfig.get(`/admin/place/show/${id}`).then(res => {
            setName(res.data.place.name)
            showModal4()
            // getTTJ()
        }).catch(err => {
            console.log(err);
        })
    }
    const editeXona = () => {
        axiosConfig.put(`/admin/room/edit/${id}`, { name, active, accommodation_id, block_id, floor_id }).then(res => {
            // message.success(res.data.message)
            setName("")
            setActive("")
            getXona()
            handleCancel2()
        }).catch(err => {
            // console.log(err.response.data.errors);
            message.error(err.response.data.errors)
            // handleCancel2()
        })
    }

    const editeJoy = () => {
        axiosConfig.put(`/admin/place/edit/${joyId}`, { name, active, accommodation_id, block_id, floor_id,room_id }).then(res => {
            // message.success(res.data.message)
            setNameJoy("")
            setActive("")
            getXona()
            handleCancel4()
        }).catch(err => {
            // console.log(err.response.data.errors);
            message.error(err.response.data.errors)
            // handleCancel2()
        })
    }

    useEffect(() => {
        getXona()
    }, [])

    const deleteEtaj = (id) => {
        axiosConfig.delete(`/admin/room/destroy/${id}`).then(res => {
            // console.log(res.data.message);
            message.success(res.data.message)
            getXona()
        }).catch(err => {
            console.log(err);
        })
    }

    const deleteJoy = (id) => {
        axiosConfig.delete(`/admin/place/destroy/${id}`).then(res => {
            // console.log(res.data.message);
            message.success(res.data.message)
            getXona()
        }).catch(err => {
            console.log(err);
        })
    }

    const [arrow, setArrow] = useState('Show');

    //   

    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }
        if (arrow === 'Show') {
            return true;
        }
        return {
            pointAtCenter: true,
        };
    }, [arrow]);


    const role = sessionStorage.getItem('role')
    const columns = [
        {
            title: '№',
            width: '5%',
            render: (text, row, index) => (
                <>
                    {index + 1}
                </>
            ),
        },
        {
            title: 'TTJ nomi',
            render: (text, row) => (
                <>
                    {row.accommodation.name}
                </>
            ),
            width: '15%',
        },
        {
            title: 'TTJ va Block nomi',
            render: (text, row) => (
                <>
                    {row.block.name}
                </>
            ),
            width: '15%',
        },
        {
            title: 'TTJ va Block qavati',
            render: (text, row) => (
                <>
                    {row.floor.name}
                </>
            ),
            width: '15%',
        },
        {
            title: 'Xona',
            dataIndex: 'name',
            key: 'name',
            width: '10%',

        },
        {
            title: <div className="text-center">Joy</div>,
            render: (texts, row) => (
                <div>
                    {row.places?.map((item, index) => (
                        <div key={index} style={{ display: "inline-block", flex: "wrap", margin: "0 10px", justifyContent: "space-around" }}>
                            {/* {console.log(item)} */}
                            <Tooltip className="border" placement="top" title={
                                
                                    role == 3 ? "" :
                                    <span>
                                <EditOutlined style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => { showModal4(); getJoyEdit(item.id);setJoyId(item.id);sessionStorage.setItem("roomId",row.id);}} />
                                <DeleteOutlined style={{ fontSize: "20px", marginLeft: "20px", cursor: "pointer", color: "red" }} onClick={()=>deleteJoy(item.id)}/>
                              </span>
                                
                           
                            } arrow={mergedArrow}>
                                <button style={{ padding: "3px 5px", margin: "0 0 10px 0", borderRadius: "4px", backgroundColor: `${item.is_busy  == 1 || item.active == 0 ? "#ff021a" : " #267651"}`, color: "white" }}>{item.name}</button>
                            </Tooltip>
                        </div>
                    ))}
                    {
                        role == 3 ? "" :
                    <PlusCircleOutlined style={{ fontSize: "20px", margin: "10px 0 0 15px", cursor: "pointer" }}
                        onClick={() => {
                            showModal3()
                            sessionStorage.setItem("roomId", row.id)
                        }} />
                    }
                </div>
            ),
            width: '15%',

        },
        {
            title: 'Holati',
            dataIndex: 'active',
            key: 'active',
            width: '12%',
            render: (text, row) => (
                <div>
                    {row.active == "1" ?
                        <div style={{ height: "25px", width: "70px", display: "flex", alignItems: "center", justifyContent: "center" }} className="btn btn-secondary">Active</div>
                        :
                        <div style={{ height: "25px", width: "70px", display: "flex", alignItems: "center", justifyContent: "center" }} className="btn btn-danger">Passive</div>}
                </div>
            ),
        },
        {
            title:  role == 3 ? "" : "Amallar" ,
            width: role == 3 ? "" : '25%',
            render: (text, row) => (
                <div>
                    {
                        role == 3 ? "" :
                    <EditOutlined style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => { getEdit(row.id); setId(row.id); showModal2() }} />
                    }
                    {/* <PlusCircleOutlined style={{ fontSize: "20px", marginLeft: "25px", cursor: "pointer" }}
                        onClick={() => {
                            showModal3()
                            sessionStorage.setItem("roomId", row.id)
                        }} /> */}
                    {/* <DeleteOutlined style={{ fontSize: "20px", marginLeft: "20px", cursor: "pointer", color: "red" }} onClick={() => deleteEtaj(row.id)} /> */}
                </div>
            ),
        },
    ];

    return (
        <div>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <Breadcrumb
                    items={[
                        {
                            title: <Link to={"/super admin dashboard"}> <HomeOutlined /> TTJ</Link>,
                        },
                        {
                            title: <Link to={"/super admin dashboard/create block"}> Block</Link>,
                        },
                        {
                            title: <Link to={"/super admin dashboard/create etaj"}> Qavat</Link>,
                        },
                        {
                            title: 'Xona',
                        },

                    ]}
                />
                {
                    role == 3 ? "" : <Button type="primary" onClick={showModal} >
                    Xona qo'shish
                </Button>
                }
            </div>
            <hr />
            <Table columns={columns} dataSource={data} pagination={false} />

            <Modal
                open={open}
                title="Qavat ga xona biriktirish"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={() => (
                    <div>
                        <div>
                            <div>
                                {
                                    name ? <Button type="primary" htmlType="submit" onClick={onFinish}>Qo'shish</Button> : <Button type="primary" htmlType="submit" disabled>Qo'shish</Button>
                                }
                            </div>
                        </div>

                    </div>
                )}
            >
                <div>
                    <label className="mt-2" htmlFor="">Xona nomi</label>
                    <input value={name} style={{ boxShadow: "none" }} required placeholder="Xona kiriting" className="form-control" onChange={(e) => setName(e.target.value)} type="text" />
                </div>
            </Modal>
            <Modal
                open={open2}
                title="Qavatni O'zgartirish"
                onOk={handleOk2}
                onCancel={handleCancel2}
                footer={() => (
                    <div>
                        <div>
                            <Button type="primary" htmlType="submit" onClick={editeXona}>O'zgartirish</Button>
                        </div>
                    </div>
                )}
            >
                <div>
                    <label className="mt-2" htmlFor="">Qavat nomi</label>
                    <input value={name} style={{ boxShadow: "none" }} required placeholder="TTJ nomini kiriting" className="form-control" onChange={(e) => setName(e.target.value)} type="text" />
                </div>
                <div>
                    <label className="mt-4" htmlFor="">Holatini tanlng</label>
                    <select className="form-select" style={{ boxShadow: "none" }} onChange={(e) => setActive(e.target.value)} name="" id="">
                        <option disabled selected>Holatni tanlang</option>
                        <option value="1">Active</option>
                        <option value="0">Passive</option>
                    </select>
                </div>
            </Modal>
            <Modal
                open={open3}
                title="Xonaga ga joy biriktirish"
                onOk={handleOk3}
                onCancel={handleCancel3}
                footer={() => (
                    <div>
                        <div>
                            <div>
                                {
                                    name ? <Button type="primary" htmlType="submit" onClick={onFinish2}>Qo'shish</Button> : <Button type="primary" htmlType="submit" disabled>Qo'shish</Button>
                                }
                            </div>
                        </div>

                    </div>
                )}
            >
                <div>
                    <label className="mt-2" htmlFor="">Joy nomi</label>
                    <input value={name} style={{ boxShadow: "none" }} required placeholder="Joy kiriting" className="form-control" onChange={(e) => setName(e.target.value)} type="text" />
                </div>
            </Modal>

            <Modal
                open={open4}
                title="Joyni ozgartirish"
                onOk={handleOk4}
                onCancel={handleCancel4}
                footer={() => (
                    <div>
                        <div>
                            <div>
                                {
                                    name ? <Button type="primary" htmlType="submit" onClick={editeJoy}>O'zgartirish</Button> : <Button type="primary" htmlType="submit" disabled>O'zgartirish</Button>
                                }
                            </div>
                        </div>

                    </div>
                )}
            >
                <div>
                    <label className="mt-2" htmlFor="">Joy nomi</label>
                    <input value={name} style={{ boxShadow: "none" }} required placeholder="Joy kiriting" className="form-control" onChange={(e) => setName(e.target.value)} type="text" />
                </div>
                <div>
                    <label className="mt-4" htmlFor="">Holatini tanlng</label>
                    <select className="form-select" style={{ boxShadow: "none" }} onChange={(e) => setActive(e.target.value)} name="" id="">
                        <option disabled selected>Holatni tanlang</option>
                        <option value="1">Active</option>
                        <option value="0">Passive</option>
                    </select>
                </div>
            </Modal>
        </div>
    )
}

export default CreateXona;