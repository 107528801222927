import "./statistics.css";
import { useState, useEffect } from "react";
import axiosConfig from "../../BaseUrl";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import FileSaver from "file-saver"

function StatisticsTTJ() {
  const [accommodation, setAccommodation] = useState([]);
  const [ttjData, setTtjData] = useState([]);



  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const exportToCSV = () => {
      axiosConfig.get(`/ttj-rahbari/places-all`).then(res=>{
        // console.log(res.data.places);
        let data = res.data.places
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const datas = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(datas, `${new Date().getFullYear()} - TTJ statistikasi` + fileExtension);
      }).catch(err=>{
        console.log(err);
      })
        
      };

  useEffect(() => {
    const getAccommodationData = async () => {
      try {
        const { data } = await axiosConfig.get(
          "/ttj-rahbari/accommodation-statistic"
        );
        setAccommodation(data);
      } catch (error) {
        console.error(error);
      }
    };
    getAccommodationData();
  }, []);

  useEffect(() => {
    const getTtjData = async () => {
      try {
        const { data } = await axiosConfig.get(
          "/ttj-rahbari/accommodation-places"
        );
        setTtjData(data.accommodations);
      } catch (error) {
        console.error(error);
      }
    };
    getTtjData();
  }, []);

  return (
    <div className="ttj">
     <div style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
     <h5 className="ttj-title">TTJ STATISTIKASI</h5>
     <button className="btn btn-primary" onClick={exportToCSV}>excel</button>
     </div>
      <hr />
      <div className="row ">
        <div className="col-xxl-3 col-md-6">
          <div className="card info-card sales-card bg-primary border-0">
            <div className="card-body">
              <h5 className="card-title card-info text-white">
                {accommodation.length !== 0 &&
                  "Turar joylar soni: " +
                    accommodation.countAccommodations +
                    " ta"}
              </h5>
            </div>
          </div>
        </div>
        <div className="col-xxl-3 col-md-6">
          <div className="card info-card sales-card bg-warning border-0">
            <div className="card-body">
              <h5 className="card-title text-white">
                {accommodation.length !== 0 &&
                  "Umumiy joylar soni: " + accommodation.countAllPlaces + " ta"}
              </h5>
            </div>
          </div>
        </div>
        <div className="col-xxl-3 col-md-6">
          <div className="card info-card sales-card bg-danger border-0">
            <div className="card-body">
              <h5 className="card-title text-white">
                {accommodation.length !== 0 &&
                  "Band joylarni soni: " +
                    accommodation.countBusyPlaces +
                    " ta"}
              </h5>
            </div>
          </div>
        </div>
        <div className="col-xxl-3 col-md-6">
          <div className="card info-card sales-card bg-success border-0">
            <div className="card-body">
              <Link to="/TTJ admin dashboard/availablePlaces">
                <h5 className="card-title text-white">
                  {accommodation.length !== 0 &&
                    "Bo'sh joylarni soni: " +
                      Number(
                        accommodation.countAllPlaces -
                          accommodation.countBusyPlaces
                      ) +
                      " ta"}
                </h5>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="ttj-info">
        <div className="row test">
          <div className="col-xxl-3 col-sm-3 text-center fw-bold">TTJ nomi</div>
          <div className="col-xxl-3 col-sm-3 text-center fw-bold">
            TTJ joylar soni
          </div>
          <div className="col-xxl-3 col-sm-3 text-center fw-bold">
            TTJ band joylar soni
          </div>
          <div className="col-xxl-3 col-sm-3 text-center fw-bold">
            TTJ bo'sh joylar soni
          </div>
        </div>
        {ttjData.map((item) => (
          <div key={item.id} className="row test">
            <div className="col-xxl-3 col-sm-3 text-center">
              <Link to={`/TTJ admin dashboard/ttj/block/${item.id}`}>
                {item.name}
              </Link>
            </div>
            <div className="col-xxl-3 col-sm-3 text-center">
              {item.allPlaces}
            </div>
            <div className="col-xxl-3 col-sm-3 text-center">
              {item.busyPlaces}
            </div>
            <div className="col-xxl-3 col-sm-3 text-center">
              {item.allPlaces - item.busyPlaces}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StatisticsTTJ;
