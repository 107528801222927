import React, { useState } from "react";
import logo from "../assets/logoadu.png";
import {
  ProfileOutlined,
  BankOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import PrivateUsernRoute from "../Privates/PrivateUserRoute";
import AddTalabaPlace from "./pages/AddTalabaPlace/AddTalabaPlace";
import MyProfile from "./pages/myProfile/MyProfile";
import TalabaList from "./pages/TalabaList/TalabaList";
import { FcStatistics } from "react-icons/fc";
import Block from "./statistics/Block";
import Floor from "./statistics/Floor";
import Room from "./statistics/Room";
import Place from "./statistics/Place";
import AvailablePlaces from "./statistics/AvailablePlaces";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const AdminMain = () => {
  const navigate = useNavigate();
  const ttName = sessionStorage.getItem("ttName");
  const items = [
    getItem(
      <div onClick={() => navigate("/admin dashboard")}> {ttName}</div>,
      "1",
      <BankOutlined onClick={() => navigate("/admin dashboard")} />
    ),
    // getItem(<div  onClick={()=>navigate('/dashboard/create admin')}>biriktirish</div>, '2', <DesktopOutlined onClick={()=>navigate('/super admin dashboard/create admin')} />),
    // getItem('User', 'sub1', <UserOutlined />, [
    //     getItem('Tom', '3'),
    //     getItem('Bill', '4'),
    //     getItem('Alex', '5'),
    // ]),
    getItem(
      <div onClick={() => navigate("/admin dashboard/students")}>
        Talabalar ro'yxati
      </div>,
      "3",
      <UsergroupAddOutlined
        onClick={() => navigate("/admin dashboard/students")}
      />
    ),
    getItem(
      <div onClick={() => navigate("/admin dashboard/my-profile")}>
        Mening profilim
      </div>,
      "2",
      <ProfileOutlined
        onClick={() => navigate("/admin dashboard/my-profile")}
      />
      
    ),
    getItem(
        <div onClick={() => navigate("/admin dashboard/block")}>
          TTJ statistika
        </div>,
        "4",
        <FcStatistics onClick={() => navigate("/admin dashboard/block")} />
      ),
  ];
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const logOut = () => {
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <div>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div className="demo-logo mt-3"></div>
          <div className="demo-logo-vertical demo-logo">
            <img className="img-fluid" src={logo} alt="" />
          </div>
          <Menu
            style={{ padding: "30px 0 0 0" }}
            theme="dark"
            defaultSelectedKeys={["1"]}
            mode="inline"
            items={items}
          />
        </Sider>
        <Layout>
          {/* <Header style={{ padding: 10, background: colorBgContainer, }} /> */}
          <Content style={{ margin: "0 16px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>
                  {sessionStorage.getItem("name")}
                </Breadcrumb.Item>
              </Breadcrumb>
              <div>
                <button
                  style={{
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={logOut}
                  className="btn btn-danger"
                >
                  <LogoutOutlined /> Chiqish
                </button>
              </div>
            </div>

            <div
              style={{
                padding: 24,
                minHeight: 360,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Routes>
                <Route element={<PrivateUsernRoute />}>
                  <Route path="/" element={<AddTalabaPlace />} />
                  <Route path="/my-profile" element={<MyProfile />} />
                  <Route path="/students" element={<TalabaList />} />

                  <Route path="/block" element={<Block />} />
                  <Route path="/block/:blockId" element={<Floor />} />
                  <Route
                    path="/block/:blockId/floor/:floorId"
                    element={<Room />}
                  />
                  <Route
                    path="/block/:blockId/floor/:floorId/room/:roomId"
                    element={<Place />}
                  />
                  <Route
                    path="/availablePlaces"
                    element={<AvailablePlaces />}
                  />
                </Route>
              </Routes>
            </div>
          </Content>

          <Footer
            style={{
              textAlign: "center",
            }}
          >
            TTJ ADU {new Date().getFullYear()} Created by <b>RTTRM</b>
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default AdminMain;
