import { useState, useEffect } from "react";
import axiosConfig from "../../BaseUrl";
import { Link, useParams } from "react-router-dom";

function StatisticsRoom() {
  const { blockId, floorId } = useParams();
  const [roomData, setRoomData] = useState([]);

  useEffect(() => {
    const getRoomData = async () => {
      try {
        const { data } = await axiosConfig.get(
          `/super-admin/room-places/${floorId}`
        );
        setRoomData(data.rooms);
      } catch (error) {
        console.error(error);
      }
    };
    getRoomData();
  }, []);

  return (
    <div className="ttj">
      <div className="d-flex justify-content-between">
        <h5 className="ttj-title">
          <Link to={`/admin dashboard/block`}>Blok</Link> /
          <Link to={`/admin dashboard/block/${blockId}`}> Etaj</Link> / Xona
        </h5>
        <h5 className="ttj-title2">TTJ XONA STATISTIKASI</h5>
      </div>
      <hr />
      <table className="table my-4">
        <thead>
          <tr>
            <th>№</th>
            <th>Xona nomi</th>
            <th>Xona joylar soni</th>
            <th>Xona band joylar soni</th>
            <th>Xona bo'sh joylar soni</th>
          </tr>
        </thead>
        <tbody>
          {roomData.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>
                <Link
                  to={`/admin dashboard/block/${blockId}/floor/${floorId}/room/${item.id}`}
                >
                  {item.name}
                </Link>
              </td>
              <td>{item.allPlaces}</td>
              <td>{item.busyPlaces}</td>
              <td>{item.allPlaces - item.busyPlaces}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default StatisticsRoom;
