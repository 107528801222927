import React, { useState, useEffect } from "react";
import { DeleteOutlined, EditOutlined, EyeTwoTone, HomeOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Form, Breadcrumb, message, } from 'antd';
import Highlighter from 'react-highlight-words';
import axiosConfig from "../../../BaseUrl";
import { useNavigate, Link } from "react-router-dom";



const MainTTJ = () => {

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [name, setName] = useState("")
    const [checkActive, setCheckActive] = useState(false)
    const [active, setActive] = useState('')
    const [id, setId] = useState('')
    const [form] = Form.useForm();
    const navigate = useNavigate()

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setOpen(false);
    };
    const handleCancel = () => {
        setName("")
        setOpen(false);
        setId("")
    };
    const showModal2 = () => {
        setOpen2(true);
    };
    const handleOk2 = () => {
        setOpen2(false);
    };
    const handleCancel2 = () => {
        setName("")
        setOpen2(false);
        setId("")
    };

    const onFinish = () => {
        handleCancel()
        axiosConfig.post(`/super-admin/accommodation`, { name }).then(res => {
            console.log(res.data);
            message.success(res.data.message)
            setName("")
            form.resetFields();
            getTTJ()
        }).catch(err => {
            console.log(err.response.data.errors);
            message.error(err.response.data.errors)
        })
    };

    const onFinishFailed = (errorInfo) => {
        //   console.log("Salom");
        // setText("Maydonlarni to'liq to'ldiring")
    };
    const [data, setData] = useState();

    const getTTJ = () => {
        axiosConfig.get(`/super-admin/accommodation`).then(res => {
            setData(res.data.accommodation)
        }).catch(err => {
            console.log(err);
        })
    }

    const getEdit = (id) => {
        axiosConfig.get(`/super-admin/accommodation/show/${id}`).then(res => {
            console.log(res.data.accommodation);
            // message.success(res.data.message)
            setName(res.data.accommodation.name)
            showModal2()
            // getTTJ()
        }).catch(err => {
            console.log(err);
        })
    }

    const editeTTJ = () => {
        axiosConfig.put(`/super-admin/accommodation/edit/${id}`, { name, active }).then(res => {
            // console.log(res.data);
            // message.success(res.data.message)
            setName("")
            setActive("")
            getTTJ()
            setCheckActive(false)
            handleCancel2()
        }).catch(err => {
            console.log(err);
            // handleCancel2()
        })
    }

    useEffect(() => {
        getTTJ()
    }, [])

    const deleteTTJ = (id) => {
        axiosConfig.delete(`/super-admin/accommodation/destroy/${id}`).then(res => {
            // console.log(res.data.message);
            message.success(res.data.message)
            getTTJ()
        }).catch(err => {
            console.log(err);
        })
    }

    const role = sessionStorage.getItem('role')
    const columns = [
        {
            title: '№',
            dataIndex: 'address',
            key: 'address',
            width: '5%',
            render: (text, row, index) => (
                <>
                    {index + 1}
                </>
            ),
        },
        {
            title: 'TTJ nomi',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
        },
        {
            title: 'Holati',
            dataIndex: 'active',
            key: 'active',
            width: '20%',
            render: (text, row) => (
                <div>
                    {row.active == "1" ?
                        <div style={{ height: "25px", width: "70px", display: "flex", alignItems: "center", justifyContent: "center" }} className="btn btn-secondary">Active</div>
                        :
                        <div style={{ height: "25px", width: "70px", display: "flex", alignItems: "center", justifyContent: "center" }} className="btn btn-danger">Passive</div>}
                </div>
            ),
        },
        {
            title: 'Amallar',
            width: '20%',
            render: (text, row) => (
                <div>
                    {
                        role == 3 ? "" : <EditOutlined style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => { getEdit(row.id); setId(row.id); showModal2() }} />
                    }
                    <EyeTwoTone onClick={() => {
                        navigate('/super admin dashboard/create block');
                        sessionStorage.setItem("ttId", row.id)
                    }} style={{ fontSize: "20px", cursor: "pointer", marginLeft: "20px" }} />
                    {/* <DeleteOutlined style={{ fontSize: "20px", marginLeft: "20px", cursor: "pointer", color: "red" }} onClick={() => deleteTTJ(row.id)} /> */}
                </div>
            ),
        },
    ];

    // console.log(checkActive);

    return (
        <div>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <div>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to={"/super admin dashboard"}> <HomeOutlined /> TTJ</Link>,
                            },

                        ]}
                    />
                </div>
                {
                    role == 3 ? "" :
                        <Button type="primary" onClick={showModal} >
                            TTJ qo'shish
                        </Button>
                }
            </div>
            <hr />
            <Table columns={columns} dataSource={data} pagination={false} />
            <Modal
                open={open}
                title="TTJ qo'shish"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={() => (
                    <div>
                        <div>
                            <div>
                                {
                                    name ? <Button type="primary" htmlType="submit" onClick={onFinish}>Qo'shish</Button> : <Button type="primary" htmlType="submit" disabled>Qo'shish</Button>
                                }
                            </div>
                        </div>

                    </div>
                )}
            >
                <div>
                    <label className="mt-2" htmlFor="">TTJ nomi</label>
                    <input value={name} style={{ boxShadow: "none" }} required placeholder="TTJ nomini kiriting" className="form-control" onChange={(e) => setName(e.target.value)} type="text" />
                </div>
            </Modal>
            <Modal
                open={open2}
                title="TTJ O'zgartirish"
                onOk={handleOk2}
                onCancel={handleCancel2}
                footer={() => (
                    <div>
                        <div>
                            <Button type="primary" htmlType="submit" onClick={editeTTJ}>O'zgartirish</Button>
                        </div>
                    </div>
                )}
            >
                <div>
                    <label className="mt-2" htmlFor="">TTJ nomi</label>
                    <input value={name} style={{ boxShadow: "none" }} required placeholder="TTJ nomini kiriting" className="form-control" onChange={(e) => setName(e.target.value)} type="text" />
                </div>
                <div>
                    <label className="mt-4" htmlFor="">Holatini tanlng</label>
                    <select className="form-select" style={{ boxShadow: "none" }} onChange={(e) => setActive(e.target.value)} name="" id="">
                        <option disabled selected>Holatni tanlang</option>
                        <option value="1">Active</option>
                        <option value="0">Passive</option>
                    </select>
                </div>
            </Modal>
        </div>
    )
}

export default MainTTJ;