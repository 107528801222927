import React, { useEffect, useState, useRef } from "react";
import { Button, Input, Space, Table, Breadcrumb, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import axiosConfig from "../../BaseUrl";

const TTJboshliqArxiv = () => {


    const [data, setData] = useState();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [open, setOpen] = useState(false);

    const [ttj, setTTJ] = useState();
    const [block, settBlock] = useState()
    const [floor, setFloor] = useState()
    const [xona, setXona] = useState()
    const [joy, setJoy] = useState()

    const [retryAddStudentData, setRetryAddStudentData] = useState({
        accommodation_id: "",
        block_id: "",
        floor_id: "",
        room_id: "",
        place_id: "",
    })


    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setOpen(false);
    };
    const handleCancel = () => {
        setOpen(false);
    };


    const getSudentList = () => {
        axiosConfig.get(`/ttj-rahbari/students-arxiv`).then(res => {
            console.log(res.data);
            setData(res.data.students)
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getSudentList()
    }, [])



    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };


    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const getTTJ = () => {
        axiosConfig.get(`/ttj-rahbari/accommodation-freeplaces`).then(res => {
            setTTJ(res.data.accommodations)
        }).catch(err => {
            console.log(err);
        })
    }
    const getBlock = () => {
        if (retryAddStudentData.accommodation_id) {
            axiosConfig.get(`/ttj-rahbari/block-freeplaces/${retryAddStudentData.accommodation_id}`).then(res => {
                settBlock(res.data.blocks)
                // console.log(res.data.blocks);
            }).catch(err => {
                console.log(err);
            })
        }
    }
    const getEtaj = () => {
        if (retryAddStudentData.block_id) {
            axiosConfig.get(`/ttj-rahbari/floor-freeplaces/${retryAddStudentData.block_id}`).then(res => {
                setFloor(res.data.floors)
                // console.log(res.data.floors);
            }).catch(err => {
                console.log(err);
            })
        }
    }
    const getXona = () => {
        if (retryAddStudentData.floor_id) {
            axiosConfig.get(`/ttj-rahbari/room-freeplaces/${retryAddStudentData.floor_id}`).then(res => {
                // console.log(res.data.rooms);
                setXona(res.data.rooms)
            }).catch(err => {
                console.log(err);
            })
        }
    }
    const getJoy = () => {
        if (retryAddStudentData.room_id) {
            axiosConfig.get(`/ttj-rahbari/place-freeplaces/${retryAddStudentData.room_id}`).then(res => {
                // console.log(res.data.places);
                setJoy(res.data.places)
            }).catch(err => {
                console.log(err);
            })
        }
    }
    useEffect(() => {
        getTTJ()
    }, [])

    useEffect(() => {
        getBlock()
    }, [retryAddStudentData.accommodation_id])

    useEffect(() => {
        getEtaj()
    }, [retryAddStudentData.block_id])

    useEffect(() => {
        getXona()
    }, [retryAddStudentData.floor_id])

    useEffect(() => {
        getJoy()
    }, [retryAddStudentData.room_id])

    const [catchStudentId, setCatchStudentId] = useState()

    const retyAddPlace = () => {
        axiosConfig.put(`/ttj-rahbari/student-attach/${catchStudentId}`, retryAddStudentData).then(res=>{
            getSudentList()
            setOpen(false)
            setCatchStudentId('')
        }).catch(err=>{
            console.log(err.response);
        })
    }


    const handleChange = (e) => {
        setRetryAddStudentData(() => {
            return {
                ...retryAddStudentData,
                [e.target.name]: e.target.value,
            };
        });
    };

    const columns = [
        {
            title: '№',
            dataIndex: 'address',
            key: 'address',
            width: '5%',
            render: (text, row, index) => (
                <>
                    {index + 1}
                </>
            ),
        },
        {
            title: 'F.I.O',
            dataIndex: 'fullname',
            key: 'fullname',
            width: '15%',
            ...getColumnSearchProps('fullname'),
        },
        {
            title: "TTJ nomi",
            dataIndex: 'education_form',
            key: 'education_form',
            width: '10%',
            render: (text, row) => (
                <>
                    {row.accommodation?.name}
                </>
             ),
        },
        {
            title: "Blok nomi",
            dataIndex: 'specialty',
            key: 'specialty',
            width: '10%',
            render: (text, row) => (
                <>
                    {row.block?.name}
                </>
             ),
        },
        {
            title: "Qavat nomi",
            dataIndex: 'specialty',
            key: 'specialty',
            width: '10%',
            render: (text, row) => (
                <>
                   {row.floor?.name}
                </>
             ),
        },
        {
            title: "Xona raqami",
            dataIndex: 'specialty',
            key: 'specialty',
            width: '10%',
            render: (text, row) => (
                <>
                   {row.room?.name}
                </>
             ),
        },
        {
            title: "Joy raqami",
            dataIndex: 'specialty',
            key: 'specialty',
            width: '10%',
            render: (text, row) => (
                <>
                   {row.place?.name}
                </>
             ),
        },
        {
            title: "Chiqqan sanasi",
            dataIndex: 'exit_date',
            key: 'exit_date',
            width: '10%',
        },
        {
            title: 'HEMIS ID',
            dataIndex: 'hemis_id',
            key: 'hemis_id',
            width: '15%',
            ...getColumnSearchProps('hemis_id'),
        },
        {
            title: 'Amallar',
            width: '10%',
            render: (text, row) => (
                <div>
                    <Button
                        size="small"
                        onClick={() => { showModal(true); setCatchStudentId(row.id)}}
                    >
                        Qayta joylash
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div>
            <div>
                <Breadcrumb
                    items={[
                        {
                            title: `Arxiv`,
                        },

                    ]}
                />
            </div>
            <hr />
            <Table columns={columns} dataSource={data} />

            <Modal
                open={open}
                title="Qayta joylash"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={() => (
                    <div>
                        <div>
                            <div>
                                <Button type="primary" htmlType="submit" onClick={retyAddPlace}>Qo'shish</Button>
                            </div>
                        </div>

                    </div>
                )}
            >
                <div>
                    <hr />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">TTJ nomi</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">

                                            <select onChange={(e) => handleChange(e)} name='accommodation_id' style={{ boxShadow: "none" }} className='form-select'>
                                                <option selected disabled>Tanlang</option>
                                                {
                                                    ttj?.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </select>

                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Block nomi</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            <select onChange={(e) => handleChange(e)} name='block_id' style={{ boxShadow: "none" }} className='form-select'>
                                                <option selected disabled>Tanlang</option>
                                                {
                                                    block?.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Qavat</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            <select onChange={(e) => handleChange(e)} name='floor_id' style={{ boxShadow: "none" }} className='form-select'>
                                                <option selected disabled>Tanlang</option>
                                                {
                                                    floor?.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Xona</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            <select onChange={(e) => handleChange(e)} name='room_id' style={{ boxShadow: "none" }} className='form-select'>
                                                <option selected disabled>Tanlang</option>
                                                {
                                                    xona?.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Joy</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary mb-1">
                                            <select onChange={(e) => handleChange(e)} name='place_id' style={{ boxShadow: "none" }} className='form-select'>
                                                <option selected disabled>Tanlang</option>
                                                {
                                                    joy?.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </select>

                                        </div>
                                    </div>
                                    <hr />
                               
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default TTJboshliqArxiv;