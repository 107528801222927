import { useState, useEffect } from "react";
import axiosConfig from "../../BaseUrl";
import { Link, useParams } from "react-router-dom";

function Place() {
  const { blockId, floorId, roomId } = useParams();
  const [placeData, setPlaceData] = useState([]);

  useEffect(() => {
    const getFloorData = async () => {
      try {
        const { data } = await axiosConfig.get(
          `/super-admin/place-students/${roomId}`
        );
        setPlaceData(data.places);
      } catch (error) {
        console.error(error);
      }
    };
    getFloorData();
  }, []);

  return (
    <div className="ttj">
      <div className="d-flex justify-content-between">
        <h5 className="ttj-title">
          <Link to={`/admin dashboard/block`}>Blok</Link> /
          <Link to={`/admin dashboard/block/${blockId}`}> Etaj</Link> /
          <Link to={`/admin dashboard/block/${blockId}/floor/${floorId}`}> Xona</Link> / Joy
        </h5>
        <h5 className="ttj-title2">TTJ JOY STATISTIKASI</h5>
      </div>
      <hr />
      <table className="table my-4">
        <thead>
          <tr>
            <th>Joy nomi</th>
            <th>Talaba hemis id</th>
            <th>Talaba to'liq ismi</th>
            <th>Talaba fakulteti</th>
          </tr>
        </thead>
        <tbody>
          {placeData.map((item) => {
            if (item.students.length === 1) {
              return (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.students[0].hemis_id}</td>
                  <td>{item.students[0].fullname}</td>
                  <td>{item.students[0].department}</td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Place;
