import React, { useState, useRef, useEffect } from "react";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Form, Breadcrumb, message, } from 'antd';
import Highlighter from 'react-highlight-words';
import axiosConfig from "../../../BaseUrl";
import { Link } from "react-router-dom";



const CreateAdmin = () => {

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [accommodation_id, setAccommodation_id] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [password_confirmation, setPassword_confirmation] = useState("")
    const [checkActive, setCheckActive] = useState(false)
    const [active, setActive] = useState('')
    const [id, setId] = useState('')
    const [form] = Form.useForm();

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setOpen(false);
    };
    const handleCancel = () => {
        setName("")
        setOpen(false);
        setId("")
    };

    const showModal2 = () => {
        setOpen2(true);
    };
    const handleOk2 = () => {
        setOpen2(false);
    };
    const handleCancel2 = () => {
        setName("")
        setOpen2(false);
        setId("")
    };

    const onFinish = () => {
        handleCancel()
        axiosConfig.post(`/super-admin/admin`, { accommodation_id, name, email, password, password_confirmation }).then(res => {
            console.log(res.data);
            message.success(res.data.message)
            setAccommodation_id("")
            setName("")
            setEmail("")
            setPassword("")
            setPassword_confirmation("")
            getAdmin()
        }).catch(err => {
            // console.log(err.response.data);
            message.error(err.response.data.errors)
        })
    };

    const onFinishFailed = (errorInfo) => {
        //   console.log("Salom");
        // setText("Maydonlarni to'liq to'ldiring")
    };
    const [data, setData] = useState();
    const [ttj, setTtj] = useState();

    const getTTJ = () => {
        axiosConfig.get(`/super-admin/accommodation`).then(res => {
            // console.log(res.data.accommodation);
            setTtj(res.data.accommodation)
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getTTJ()
    }, [])

    const getAdmin = () => {
        axiosConfig.get(`/super-admin/admin`).then(res => {
            // console.log(res.data.admins);
            setData(res.data.admins)
        }).catch(err => {
            console.log(err);
        })
    }

    const getEdit = (id) => {
        axiosConfig.get(`/super-admin/admin/show/${id}`).then(res => {
            console.log(res.data.admin);
            // message.success(res.data.message)
            setName(res.data.admin.name)
            setEmail(res.data.admin.email)
            showModal2()
            // getTTJ()
        }).catch(err => {
            console.log(err);
        })
    }

    const editeAdmin = () => {
        axiosConfig.put(`/super-admin/admin/edit/${id}`, { accommodation_id, name, email, active }).then(res => {
            console.log({ accommodation_id, name, email, active });
            // message.success(res.data.message)
            setName("")
            setEmail("")
            setAccommodation_id("")
            setActive("")
            getAdmin()
            setCheckActive(false)
            handleCancel2()
        }).catch(err => {
            console.log(err);
            // handleCancel2()
        })
    }

    useEffect(() => {
        getAdmin()
    }, [])

    const deleteAdmin = (id) => {
        axiosConfig.delete(`/super-admin/admin/destroy/${id}`).then(res => {
            // console.log(res.data.message);
            message.success(res.data.message)
            getAdmin()
        }).catch(err => {
            console.log(err);
        })
    }

    const role = sessionStorage.getItem('role')

    const columns = [
        {
            title: '№',
            dataIndex: 'address',
            width: '5%',
            render: (text, row, index) => (
                <>
                    {index + 1}
                </>
            ),
        },
        {
            title: 'TTJ nomi',
            width: '20%',
            render: (text, row) => (
                <>
                    {/* {console.log(row.accommodation.name)} */}
                    {row.accommodation.name}
                </>
            ),
        },
        {
            title: 'F.I.O',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
        },
        {
            title: 'Login',
            dataIndex: 'email',
            key: 'email',
            width: '20%',
        },
        {
            title: 'Holati',
            width: '10%',
            render: (text, row) => (
                <div>
                    {row.active == "1" ?
                        <div style={{ height: "25px", width: "70px", display: "flex", alignItems: "center", justifyContent: "center" }} className="btn btn-secondary">Active</div>
                        :
                        <div style={{ height: "25px", width: "70px", display: "flex", alignItems: "center", justifyContent: "center" }} className="btn btn-danger">Passive</div>}
                </div>
            ),
        },
        {
            title: role == 3 ? "" : "Amallar",
            width: role == 3 ? "" : '20%',
            render: (text, row) => (
                role == 3 ? "" :
                    <div>
                        <EditOutlined style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => { getEdit(row.id); setId(row.id); showModal2() }} />
                        <DeleteOutlined style={{ fontSize: "20px", marginLeft: "25px", cursor: "pointer", color: "red" }} onClick={() => deleteAdmin(row.id)} />
                    </div>
            ),
        },
    ];

    // console.log(checkActive);

    const editPassword = () => {
        axiosConfig.post(`/super-admin/admin/reset-password/${id}`, { password, password_confirmation }).then(res => {
            console.log(res.data);
            message.success(res.data.message)
            setPassword('')
            setPassword_confirmation('')
            setOpen2(false)
        }).catch(err => {
            // console.log(err.response.data.errors);
            message.error(err.response.data.errors)
        })
    }

    return (
        <div>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <div>
                    <Breadcrumb
                        items={[
                            {
                                title: 'Admin biriktirish',
                            },

                        ]}
                    />
                </div>
                {
                    role == 3 ? "" :
                        <Button type="primary" onClick={showModal} >
                            Komendant qo'shish
                        </Button>
                }
            </div>
            <hr />
            <Table columns={columns} dataSource={data} pagination={false} />
            <Modal
                open={open}
                title="Komendat qo'shish"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={() => (
                    <div>
                        <div>
                            <div>
                                {
                                    name && accommodation_id && email && password && password_confirmation ?
                                        <Button type="primary" htmlType="submit" onClick={onFinish}>Qo'shish</Button>
                                        :
                                        <Button type="primary" htmlType="submit" disabled>Qo'shish</Button>
                                }
                            </div>
                        </div>

                    </div>
                )}
            >
                <div>
                    <label className="mt-2" htmlFor="">TTJni tanlang</label>
                    <select style={{ boxShadow: "none" }} placeholder="TTJni tanlang" className="form-select" onChange={(e) => setAccommodation_id(e.target.value)} id="">
                        <option >TTJni tanlang</option>
                        {
                            ttj?.map((item, index) => (
                                <option key={index} value={item.id}>{item.name}</option>
                            ))
                        }
                    </select>
                </div>
                <div>
                    <label className="mt-2" htmlFor="">Komendat F.I.O</label>
                    <input value={name} style={{ boxShadow: "none" }} required placeholder="Komendant F.I.O" className="form-control" onChange={(e) => setName(e.target.value)} type="text" />
                </div>
                <div>
                    <label className="mt-2" htmlFor="">Login</label>
                    <input value={email} style={{ boxShadow: "none" }} required placeholder="Login" className="form-control" onChange={(e) => setEmail(e.target.value)} type="text" />
                </div>
                <div>
                    <label className="mt-2" htmlFor="">Password</label>
                    <input value={password} style={{ boxShadow: "none" }} required placeholder="Password" className="form-control" onChange={(e) => setPassword(e.target.value)} type="text" />
                </div>
                <div>
                    <label className="mt-2" htmlFor="">Confirm password</label>
                    <input value={password_confirmation} style={{ boxShadow: "none" }} required placeholder="Confirm password" className="form-control" onChange={(e) => setPassword_confirmation(e.target.value)} type="text" />
                </div>
            </Modal>
            <Modal
                open={open2}
                title="Komendat o'zgartirish"
                onOk={handleOk2}
                onCancel={handleCancel2}
                footer={() => (
                    <div>
                        <div>
                            <hr />
                            <Button type="primary" htmlType="submit" onClick={editeAdmin}>O'zgartirish</Button>
                        </div>
                    </div>
                )}
            >
                <div>
                    <label className="mt-2" htmlFor="">TTJni tanlang</label>
                    <select style={{ boxShadow: "none" }} placeholder="TTJni tanlang" className="form-select" onChange={(e) => setAccommodation_id(e.target.value)} id="">
                        <option>TTJni tanlang</option>
                        {
                            ttj?.map((item, index) => (
                                <option key={index} value={item.id}>{item.name}</option>
                            ))
                        }
                    </select>
                </div>
                <div>
                    <label className="mt-2" htmlFor="">Komendat F.I.O</label>
                    <input value={name} style={{ boxShadow: "none" }} required placeholder="Komendant F.I.O" className="form-control" onChange={(e) => setName(e.target.value)} type="text" />
                </div>
                <div>
                    <label className="mt-2" htmlFor="">Email</label>
                    <input value={email} style={{ boxShadow: "none" }} required placeholder="Email" className="form-control" onChange={(e) => setEmail(e.target.value)} type="text" />
                </div>
                <div>
                    <label className="mt-4" htmlFor="">Holatini tanlng</label>
                    <select className="form-select" style={{ boxShadow: "none" }} onChange={(e) => setActive(e.target.value)} name="" id="">
                        <option disabled selected>Holatni tanlang</option>
                        <option value="1">Active</option>
                        <option value="0">Passive</option>
                    </select>
                </div>
                <hr />
                <h4>Parolni yangilash</h4>
                <div>
                    <label className="mt-2" htmlFor="">Parol</label>
                    <input value={password} onChange={(e) => setPassword(e.target.value)} style={{ boxShadow: "none" }} required placeholder="Yangi parolni kiriting" className="form-control" type="text" />
                </div>
                <div>
                    <label className="mt-2" htmlFor="">Parol tasdig'i</label>
                    <input value={password_confirmation} onChange={(e) => setPassword_confirmation(e.target.value)} style={{ boxShadow: "none" }} required placeholder="Yangi parolni tasdig'ini kiriting" className="form-control" type="text" />
                </div>
                <div className="mt-3">
                    <Button type="primary" htmlType="submit" onClick={editPassword}>Parolni o'zgartirish</Button>
                </div>
            </Modal>
        </div>
    )
}

export default CreateAdmin;