import React, { useState } from "react";
import axiosConfig from "../../../BaseUrl"
import {
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBCard,
    MDBCardText,
    MDBCardBody,
    MDBCardImage,
    MDBBtn,
} from 'mdb-react-ui-kit';
import {LogoutOutlined} from "@ant-design/icons"
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const SuperAdminMyProfile = () => {

    const ttName = sessionStorage.getItem("ttName")
    const navigate = useNavigate()

    const [old_password, setOldPassword] = useState()
    const [password, setPassword] = useState()
    const [password_confirmation, sePasswordConfirmation] = useState()

    const changedPassword = () =>{
        axiosConfig.post(`/super-admin/changePassword`,{old_password, password, password_confirmation}).then(res=>{
            // console.log(res);
            message.success(res.data.message)
            setOldPassword("")
            setPassword("")
            sePasswordConfirmation("")
        }).catch(err=>{
            // console.log(err.response.data.errors);
            message.error(err.response.data.errors)
            setOldPassword("")
            setPassword("")
            sePasswordConfirmation("")
        })
    }

    const logOut = () =>{
        sessionStorage.clear()
        navigate('/')
    }

    return (
        <div>
            <section >
                <MDBContainer style={{padding:"10px"}} className="py-5">
                    <MDBRow className="shadow">
                        <MDBCol className="" lg="4">
                            <MDBCard  className="mb-4">
                                <MDBCardBody className="text-center">
                                    <MDBCardImage
                                        src="https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png"
                                        alt="avatar"
                                        className="rounded-circle"
                                        style={{ width: '150px' }}
                                        fluid />
                                    <p className="text-muted mb-1">{ttName == 'TTJ topilmadi' ? "Tizim admini" : `${ttName + " admini"}`}</p>
                                    <p className="text-muted mb-4">{sessionStorage.getItem("name")} </p>
                                    <div className="d-flex justify-content-center mb-2">
                                        <button onClick={logOut} className="btn btn-danger"><LogoutOutlined /> Chiqish</button>
                                    </div>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol lg="8">
                            <MDBCard  className="mb-4">
                                <MDBCardBody>
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Isim familiya</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{sessionStorage.getItem("name")}</MDBCardText>
                                        </MDBCol>
                                    </MDBRow>
                                    <hr />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Email</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{sessionStorage.getItem("email")}</MDBCardText>
                                        </MDBCol>
                                    </MDBRow>
                                    <hr />
                                    <MDBRow>
                                        <MDBCol sm="3">
                                            <MDBCardText>Boshqaruvdagi TTJ</MDBCardText>
                                        </MDBCol>
                                        <MDBCol sm="9">
                                            <MDBCardText className="text-muted">{ttName == 'TTJ topilmadi' ? "Tizim admini" : `${ttName + " admini"}`}</MDBCardText>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>

                            <MDBRow>
                               

                                <MDBCol md="12">
                                    <MDBCard  className="mb-4 ">
                                        <MDBCardBody>
                                            <MDBCardText className="mb-4"><h3>Parolni o'zgartirish</h3></MDBCardText>
                                           
                                            <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Amaldagi parol</MDBCardText>
                                            <div style={{width:"50%"}}>
                                                <input value={old_password} name="old_password" onChange={(e)=>setOldPassword(e.target.value)} type="text" style={{boxShadow:"none"}} className="form-control" />
                                            </div>

                                            <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Yangi parol</MDBCardText>
                                            <div style={{width:"50%"}}>
                                                <input value={password} name="password" onChange={(e)=>setPassword(e.target.value)} type="text" style={{boxShadow:"none"}} className="form-control" />
                                            </div>

                                            <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Yangi parol tasdig'i</MDBCardText>
                                            <div style={{width:"50%"}}>
                                                <input value={password_confirmation} name="password_confirmation" onChange={(e)=>sePasswordConfirmation(e.target.value)} type="text" style={{boxShadow:"none"}} className="form-control" />
                                            </div>
                                            <div style={{width:"50%", marginTop:"15px", display:"flex", justifyContent:"end"}}>
                                                <button onClick={changedPassword} className="btn btn-primary">O'zgartirish</button>
                                            </div>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </section>
        </div>
    )
}

export default SuperAdminMyProfile