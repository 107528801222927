import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './SuperAdmin/pages/Login';
import Main from './SuperAdmin/main';
import PrivateAdminRoute from './Privates/PrivateAdminRoute';
import PrivateUsernRoute from './Privates/PrivateUserRoute';
import PrivateTTJBoshliqRoute from './Privates/PrivateTTJBoshliqRoute';
import AdminMain from './admin/AdminMain';
import TTJadminMain from './TTJboshliq/main';


function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route element={<PrivateAdminRoute />}>
          <Route path='/super admin dashboard/*' element={<Main />} />
        </Route>
        <Route element={<PrivateUsernRoute />}>
          <Route path='/admin dashboard/*' element={<AdminMain />} />
        </Route>
        <Route element={<PrivateTTJBoshliqRoute />}>
          <Route path='/TTJ admin dashboard/*' element={<TTJadminMain />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
